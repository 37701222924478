import React, { Component } from "react";
import { ReactComponent as CloseModalIcon } from "../../../../../../assets/images/components/close-modal-icon.svg";
import { url } from "../../../../../../configurations/serverlinks";
import { axiosRequest } from "../../../../../../helpers/axiosRequest";
import FormLoader from "../../../../../../shared/loaders/FormLoader";
import Modal from "../../../../../portals/modal/Modal";

export class DeleteProperty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
    };
  }
  submitDeletingPropertyDetails = (event) => {
    event.preventDefault();
    this.setState({ isloading: true });
  
    let properties;
    if(this.props.user_type === 'admin'){
      properties = "/getProperties"
    }else if(this.props.user_type === 'user'){
      properties = "/getPropertiesOfUser";
    }
    const  id  = this.props.deleting_property; // Get the propertyId from props
    // console.log(id.id)
    const access_token = sessionStorage.getItem("access_token");
  
    axiosRequest(`${url}/deleteProperty?propertyId=${id.id}`, "DELETE", null, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    })
      .then(() => axiosRequest(`${url}${properties}`, "GET", null, {
        Authorization: `Bearer ${access_token}`,
      }))
      .then((resp) => {
        this.setState({ isloading: false });
        this.props.updatePropertyList(resp.data);
        this.props.handleCloseDeletePropertyModal();
      })
      .catch((err) => {
        this.setState({ isloading: false });
        alert(err);
      });
  };
  

  render() {
    return (
      <div>
        <div>
          <Modal modal_name="delete-modal">
            <div className="modal-container">
              <div className="modal-content">
                <p className="modal-alert-info">Are you sure you want to delete the following property?</p>
                <CloseModalIcon className="modal-close-icon" onClick={this.props.handleCloseDeletePropertyModal} />
                <form action="" className="modal-form" onSubmit={this.submitDeletingPropertyDetails}>
                  <div className="modal-form__submit-buttons">
                    <button className="modal-form__cancel-button" onClick={this.props.handleCloseDeletePropertyModal}>
                      Cancel
                    </button>
                    <button type="submit" value="Delete" className="modal-form__sumbit-button">
                      {this.state.isloading ? <FormLoader /> : "Delete"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default DeleteProperty;
