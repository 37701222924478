import React, { Component } from "react";
import "./changepasswordbutton.css";
import SettingsIcon from "../../../../../assets/images/components/settings.svg";
import ChangePassword from "../change_password/ChangePassword";
export class ChangePassswordButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display_change_password_modal: false,
    };
  }

  showChangePasswordModal = () => {
    this.setState({ display_change_password_modal: true });
  };
  closeChangePasswordModal = () => {
    this.setState({ display_change_password_modal: false });
  };
  render() {
    return (
      <>
        {this.state.display_change_password_modal ? (
          <ChangePassword closeChangePasswordModal={this.closeChangePasswordModal} handleUserLogout={this.props.handleUserLogout} />
        ) : null}
        <button onClick={this.showChangePasswordModal} className="settings-button">
          <img src={SettingsIcon} className="settings-icon" alt="settings-icon" />
          Change Password
        </button>
      </>
    );
  }
}

export default ChangePassswordButton;
