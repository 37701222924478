import React, { Component } from "react";
import { ReactComponent as CloseModalIcon } from "../../../../../../assets/images/components/close-modal-icon.svg";
import { url } from "../../../../../../configurations/serverlinks";
import { axiosRequest } from "../../../../../../helpers/axiosRequest";
import FormLoader from "../../../../../../shared/loaders/FormLoader";
import Modal from "../../../../../portals/modal/Modal";

export class DeleteUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
    };
  }
  submitDeletingUserDetails = (event) => {
    event.preventDefault();
    this.setState({ isloading: true });
    let username = this.props.deleting_user.user_name ;
    // console.log(username)
    let access_token = sessionStorage.getItem("access_token");
    axiosRequest(`${url}/deleteUser?userName=${username}`, "DELETE",null, {
    
      Authorization: `Bearer ${access_token}`,
    })
      .then(() => axiosRequest(`${url}/getUsers`, "GET", null, { Authorization: `Bearer ${access_token} ` }))
      .then((response) => {
        this.setState({ isloading: false });
        this.props.updateUserList(response.data)
        this.props.handleCloseDeleteUserModal();
      })
      .catch((err) => {
        this.setState({ isloading: false });
        alert(err);
      });
  };

  render() {
    return (
      <div>
        <Modal modal_name="delete-modal">
          <div className="modal-container">
            <div className="modal-content">
              <p className="modal-alert-info">Are you sure you want to delete the following user?</p>
              <CloseModalIcon className="modal-close-icon" onClick={this.props.handleCloseDeleteUserModal} />
              <form action="" className="modal-form" onSubmit={this.submitDeletingUserDetails}>
                <div className="modal-form__submit-buttons">
                  <button className="modal-form__cancel-button" onClick={this.props.handleCloseDeleteUserModal}>
                    Cancel
                  </button>
                  <button type="submit" value="Delete" className="modal-form__sumbit-button">
                    {this.state.isloading ? <FormLoader /> : "Delete"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DeleteUser;
