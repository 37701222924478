import React, { Component } from "react";
import { connect } from "react-redux";
import NiurixLogo from "../../../../assets/images/components/NiurixLogo.png";
import UserProfileIcon from "../../../../assets/images/components/user-profile-icon.svg";
import ChangePassswordButton from "./change_password_button/ChangePassswordButton";
import "./homepageheader.css";
import LogoutButton from "./logout_button/LogoutButton";

export class HomePageHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_dropdown: false,
    };
  }
  hideDropDown = () => {
    this.setState({ show_dropdown: false });
  };

  toggleDropdown = () => {
    this.setState((prevstate) => ({ show_dropdown: !prevstate.show_dropdown }));
  };

  render() {
    return (
      <>
        <div className="header">
          <img src={NiurixLogo} className="header__niurix-logo" alt="niurix-logo" />
          <div className="header__logout-buttons">
            <button className="admin-logout-button" onClick={this.toggleDropdown}>
              <img src={UserProfileIcon} alt="" className="user-profile-icon" />
              <div>{this.props.user_name}</div>
            </button>
            {this.state.show_dropdown && (
              <div className="user-settings">
                <ChangePassswordButton />
                <LogoutButton className="settings-button" />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user_name: state.authentication.user_name,
  };
};

export default connect(mapStateToProps, null)(HomePageHeader);
