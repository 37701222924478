import React from 'react'
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

function ProtectedRoute({ component: Component, is_user_logged_in, ...restOfProps }) {

    return (
        <Route
            {...restOfProps}
            render={(props) =>
                is_user_logged_in ? <Component {...props} /> : <Redirect to="/login" />
            }
        />
    );
}

const mapStateToProps = (state) => {
    return {
        is_user_logged_in: state.authentication.is_user_logged_in,
    };
};
export default connect(mapStateToProps, null)(ProtectedRoute);

