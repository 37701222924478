import React, { Component } from "react";
import "./homepagesidebar.css";
// import { ReactComponent as HambergurIcon } from "../../../../assets/images/components/hambergur-icon.svg";
import { ReactComponent as UserListIcon } from "../../../../assets/images/components/userIcon.svg";
import { ReactComponent as PropertyListIcon } from "../../../../assets/images/components/Propertylist-icon.svg";
import HambergurIcon from "../../../../assets/images/components/hambergur.svg";
import CloseHambergurIcon from "../../../../assets/images/components/close-modal-icon.svg";
import OutsideAlerter from "../../../../helpers/OutsideAlerter";
import { connect } from "react-redux";
export class HomePageSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_page: "property_list_page",
      expand_sidebar: false,
    };
  }

  selectInfoPage = (selected_page) => {
    this.props.setInfoContainerPage(selected_page);
    this.setState({ selected_page: selected_page });
  };
  expandSidebar = () => {
    this.setState((prevstate) => ({ expand_sidebar: !prevstate.expand_sidebar }));
  };
  contractSidebar = () => {
    this.setState({ expand_sidebar: false });
  };
  render() {
    return (
      <OutsideAlerter resetState={this.contractSidebar}>
        <div className={this.state.expand_sidebar ? "sidebar sidebar--expand" : "sidebar"}>
          {this.state.expand_sidebar ? (
            <img src={CloseHambergurIcon} className="sidebar__icon__close-icon" onClick={this.expandSidebar} alt="close-icon" />
          ) : (
            <img src={HambergurIcon} className="sidebar__icon__hambergur" onClick={this.expandSidebar} alt="hambergur-icon" />
          )}

          <div
            className={this.state.selected_page === "property_list_page" ? " sidebar__icon sidebar__icon--highlight" : "sidebar__icon"}
            onClick={() => {
              this.selectInfoPage("property_list_page")
              this.contractSidebar()
            }}
          >
            <PropertyListIcon />
            {this.state.expand_sidebar ? <span>Property List</span> : null}
          </div>
          {this.props.user_type === "admin" && (
            <div
              onClick={() => {
                this.selectInfoPage("user_list_page")
                this.contractSidebar()
              }
              }

              className={this.state.selected_page === "user_list_page" ? " sidebar__icon sidebar__icon--highlight" : "sidebar__icon"}
            >
              <UserListIcon />
              {this.state.expand_sidebar ? <span>User List</span> : null}
            </div>
          )}
        </div>
      </OutsideAlerter >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_type: state.authentication.user_type,
  };
};

export default connect(mapStateToProps, null)(HomePageSidebar);
