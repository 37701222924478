import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import AuthReducer from "./AuthReducer";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authentication"],
};
const rootReducer = combineReducers({
  authentication: AuthReducer,
});
// export default persistReducer(persis)
export default persistReducer(persistConfig, rootReducer);
