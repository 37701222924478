import React, { Component } from "react";
import LoginForm from "./login_form/LoginForm";
import NiurixLogo from "../../../assets/images/components/NiurixLogo.png";
import "./loginpage.css";
export class LoginPage extends Component {
  render() {
    return (
      <div className="login-page">
        <img src={NiurixLogo} className="login-page__niurix-logo" alt="niurix-logo" />
        <p className="login-page__welcome-title">Welcome to Niurix Admin Portal </p>
        <LoginForm setCurrentUserType={this.props.setCurrentUserType} history = {this.props.history}/>
      </div>
    );
  }
}

export default LoginPage;
