import React, { Component } from "react";
import Modal from "../../../../../portals/modal/Modal";
import { ReactComponent as CloseModalIcon } from "../../../../../../assets/images/components/close-modal-icon.svg";
import { url } from "../../../../../../configurations/serverlinks";
import { axiosRequest } from "../../../../../../helpers/axiosRequest";
import { connect } from "react-redux";
import FormLoader from "../../../../../../shared/loaders/FormLoader";

export class EditProperty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertyName: "",
      propertyAddress: "",
      oltMacAddress: "",
      oltSerialNumber: "",
      installationDate: "",
      warrantyExpiryDate: "",
      contactPerson: "",
      contactNumber: "",
      email: "",
      isloading: false,
    };
  }
  componentDidMount() {
    this.setState({
      property_name: this.props.editing_property.property_name,
      property_address: this.props.editing_property.address,
      olt_mac_address: this.props.editing_property.mac_address,
      olt_serial_number: this.props.editing_property.olt_serial_number,
      installation_date: this.props.editing_property.installation_date,
      warranty_expiry_date: this.props.editing_property.expiry_date,
      contact_person: this.props.editing_property.contact_person,
      contact_number: this.props.editing_property.contact_number,
      email: this.props.editing_property.email,
    });
  }
  handlePropertyName = (event) => {
    this.setState({ property_name: event.target.value });
  };
  handleAddress = (event) => {
    this.setState({ property_address: event.target.value });
  };
  handleOltMacAddress = (event) => {
    this.setState({ olt_mac_address: event.target.value });
  };
  handleOltSerialNumber = (event) => {
    this.setState({ olt_serial_number: event.target.value });
  };
  handleInstallationDate = (event) => {
    this.setState({ installation_date: event.target.value });
  };
  handleWarrantyExpirtyDate = (event) => {
    this.setState({ warranty_expiry_date: event.target.value });
  };
  handleContactPerson = (event) => {
    this.setState({ contact_person: event.target.value });
  };
  handleContactNumber = (event) => {
    this.setState({ contact_number: event.target.value });
  };
  handleEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  submitEditedPropertyDetails = (event) => {
    event.preventDefault();
    this.setState({ isloading: true });
    let property_id = this.props.editing_property.id;
    let new_property_credentials = {
      propertyName: this.state.property_name,
      address: this.state.property_address,
      macAddress: this.state.olt_mac_address,
      oltSerialNumber: this.state.olt_serial_number,
      installationDate: this.state.installation_date,
      expiryDate: this.state.warranty_expiry_date,
      contactPerson: this.state.contact_person,
      contactNumber: this.state.contact_number,
      email: this.state.email,
      propertyId: property_id,
    };
    let properties;
    if(this.props.user_type === 'admin'){
      properties = "/getProperties"
    }else if(this.props.user_type === 'user'){
      properties = "/getPropertiesOfUser";
    }
    let access_token = sessionStorage.getItem("access_token");
    axiosRequest(`${url}/editProperty`, "PUT", new_property_credentials, {
      "Content-Type": "application/JSON",
      Authorization: `Bearer ${access_token}`,
    })
      .then(() => axiosRequest(`${url}${properties}`, "GET", null, { Authorization: `Bearer ${access_token} ` }))
      .then((resp) => {
        if (resp.status === false) {
          this.setState({ isloading: false });
          return alert(resp.message);
        }
        this.props.updatePropertyList(resp.data);
        this.props.handleCloseEditPropertyModal();

      })
      .catch((err) => {
        this.setState({ isloading: false });

        alert(err);
      });
  
  };
  render() {
    return (
      <Modal modal_name="main-modal">
        <div className="modal-container">
          <div className="modal-content">
            <p className="modal-title"> Edit Property</p>

            <CloseModalIcon className="modal-close-icon" onClick={this.props.handleCloseEditPropertyModal} />
            <form action="" className="modal-form" onSubmit={this.submitEditedPropertyDetails}>
              <div className="modal-form__input-section">
                <label htmlFor="username" className="modal-form__label-text">
                  Property Name
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.property_name}
                  onChange={this.handlePropertyName}
                  disabled={this.props.user_type === "user" ? "disabled" : ""}
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="confirm-password" className="modal-form__label-text">
                  Property Owner
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.contact_person}
                  onChange={this.handleContactPerson}
                  disabled={this.props.user_type === "user" ? "disabled" : ""}
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section modal-form__input-section--address">
                <label htmlFor="Email" className="modal-form__label-text">
                  Address
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.property_address}
                  onChange={this.handleAddress}
                  disabled={this.props.user_type === "user" ? "disabled" : ""}
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="confirm-password" className="modal-form__label-text">
                  Contact Number
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.contact_number}
                  onChange={this.handleContactNumber}
                  disabled={this.props.user_type === "user" ? "disabled" : ""}
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="confirm-password" className="modal-form__label-text">
                  Email
                </label>
                <input
                  type="email"
                  className="modal-form__input"
                  value={this.state.email}
                  onChange={this.handleEmail}
                  disabled={this.props.user_type === "user" ? "disabled" : ""}
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section modal-form__input-section--date">
                <label htmlFor="Email" className="modal-form__label-text">
                  Install Date
                </label>
                <input
                  type="date"
                  className="modal-form__input"
                  value={this.state.installation_date}
                  onChange={this.handleInstallationDate}
                  disabled={this.props.user_type === "user" ? "disabled" : ""}
                  spellCheck={false}
                />
              </div>

              <div className="modal-form__input-section modal-form__input-section--date">
                <label htmlFor="Email" className="modal-form__label-text">
                  Warranty Expiry Date
                </label>
                <input
                  type="date"
                  className="modal-form__input"
                  value={this.state.warranty_expiry_date}
                  onChange={this.handleWarrantyExpirtyDate}
                  disabled={this.props.user_type === "user" ? "disabled" : ""}
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="Email" className="modal-form__label-text">
                  OLT MAC Address
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.olt_mac_address}
                  onChange={this.handleOltMacAddress}
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="Email" className="modal-form__label-text">
                  OLT Sr.No
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.olt_serial_number}
                  onChange={this.handleOltSerialNumber}
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__footer">
                {/* <p>You can only edit olt serial number and mac adderess</p> */}
                <button type="submit" value="Save" className="modal-form__sumbit-button">
                  {this.state.isloading ? <FormLoader /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_type: state.authentication.user_type,
  };
};

export default connect(mapStateToProps, null)(EditProperty);
