import React, { Component } from "react";
import "./CreatePass.css";
import { axiosRequest } from "./helpers/axiosRequest";
import NiurixLogo from "./assets/images/components/NiurixLogo.png";
import OpenEyeIcon from "./assets/images/components/Icon feather-eye.svg";
import CloseEyeIcon from "../src/assets/images/components/Icon feather-eye-off.svg";
import { url } from "./configurations/serverlinks";

export class CreatePass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      new_user_password: "",
      confirm_new_user_password: "",
      show_password: false,
      show_confirm_password: false,
    };
  }
  handleNewUserPassword = (event) => {
    this.setState({ new_user_password: event.target.value });
  };
  handleConfirmNewUserPassword = (event) => {
    this.setState({ confirm_new_user_password: event.target.value });
  };
  togglePasswordVisibility = () => {
    this.setState((prevstate) => ({ show_password: !prevstate.show_password }));
  };
  toggleConfirmPasswordVisibility = () => {
    this.setState((prevstate) => ({ show_confirm_password: !prevstate.show_confirm_password }));
  };
  submitPasswordCredentials = (event) => {
    event.preventDefault();
    if (this.state.new_user_password !== this.state.confirm_new_user_password) {
      return alert("Passwords did not match, please check again");
    }
    let password = this.state.confirm_new_user_password;
    const token = new URLSearchParams(this.props.location.search).get("token");
    let new_user_credentials = { password };
    console.log(new_user_credentials);
    axiosRequest(`${url}/savePassword?token=${token}`, "POST", new_user_credentials, null)
      .then(() => this.props.history.push("./login"))
      .catch((err) => alert(err));
      // axiosRequest(`${url}/resetPassword?token=${token}`, "POST", new_user_credentials, null)
      // .then(() => this.props.history.push("./login"))
      // .catch((err) => alert(err));
  };

  render() {
    return (
      <>
        <div className="login-page">
          <img src={NiurixLogo} className="login-page__niurix-logo" alt="niurix-logo" />
          <p className="login-page__welcome-title">Confirm your password</p>
          <p className="login-info">
            An Email has been sent to your mail. Please follow the steps mentioned and confirm your password here.
          </p>
          <form action="" className="login-form" onSubmit={this.submitPasswordCredentials}>
            <div>
              <label htmlFor="user_password" className="login-form__label">
                Password
              </label>
              <div className="password-container">
                <input
                  type={this.state.show_password ? "text" : "password"}
                  id="user_password"
                  className="login-form__user-input"
                  value={this.state.new_user_password}
                  onChange={this.handleNewUserPassword}
                  required
                  autoComplete="off"
                />
                <img
                  src={this.state.show_password ? CloseEyeIcon : OpenEyeIcon}
                  alt=""
                  className="login-form__password-icon"
                  onClick={this.togglePasswordVisibility}
                />
              </div>
            </div>
            <div>
              <label htmlFor="user_password" className="login-form__label">
                Confirm Password
              </label>
              <div className="password-container">
                <input
                  type={this.state.show_confirm_password ? "text" : "password"}
                  id="user_password"
                  className="login-form__user-input"
                  value={this.state.confirm_new_user_password}
                  onChange={this.handleConfirmNewUserPassword}
                  required
                  autoComplete="off"
                />
                <img
                  src={this.state.show_confirm_password ? OpenEyeIcon : CloseEyeIcon}
                  alt=""
                  className="login-form__password-icon"
                  onClick={this.toggleConfirmPasswordVisibility}
                />
              </div>
              <div className="login-form__login-section">
                <img src="" alt="" />
                <input type="submit" value="Confirm" className="login-form__login-button" />
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default CreatePass;
