import React, { Component } from "react";
import "./userlistpage.css";
import DeleteIcon from "../../../../../assets/images/components/delete-icon.svg";
import EditIcon from "../../../../../assets/images/components/edit-icon.svg";
import { axiosRequest } from "../../../../../helpers/axiosRequest";
import { url } from "../../../../../configurations/serverlinks";
import AddUser from "./add_user/AddUser";
import EditUser from "./edit_user/EditUser";
import DeleteUser from "./delete_user/DeleteUser";
import Loader from "../../../../../shared/loaders/Loader";
import addIcon from "../../../../../../src/assets/images/components/add-icon.svg";
import { connect } from "react-redux";

export class UserListPage extends Component {
  state = {
    userlist: [],
    show_add_user_modal: false,
    show_delete_user_modal: false,
    show_edit_user_modal: false,
    deleting_user: {},
    editing_user: {},
    is_loading: true,
  };

  componentDidMount() {
    // this.setState({ is_loading: true });
    console.log(this.props.user)
    let access_token = sessionStorage.getItem("access_token");
    axiosRequest(`${url}/getUsers`, "GET", null, { Authorization: `Bearer ${access_token} ` })
      .then((response) => {
        this.setState({
          is_loading: false,
          userlist: response.data,
        });
        // this.setState({ userlist: response.data });
      })
      .catch((error) => {
        alert(error);
        this.setState({ is_loading: false });
      });
  }

  updateUserList = (updated_userlist) => {
    this.setState({ userlist: updated_userlist });
  };

  handleShowAddUserModal = () => {
    this.setState({ show_add_user_modal: true });
  };
  handleCloseAddUserModal = () => {
    this.setState({ show_add_user_modal: false });
  };
  renderAddUserModal = () => {
    return <AddUser handleCloseAddUserModal={this.handleCloseAddUserModal} updateUserList={this.updateUserList} propertyName={this.props.propertyName}/>;
  };
  handleShowDeleteUserModal = (user) => {
    this.setState({ show_delete_user_modal: true });
    this.setState({ deleting_user: user });
  };

  handleCloseDeleteUserModal = () => {
    this.setState({ show_delete_user_modal: false });
  };
  renderDeleteUserModal = (deleting_user) => {
    return (
      <DeleteUser
        handleCloseDeleteUserModal={this.handleCloseDeleteUserModal}
        updateUserList={this.updateUserList}
        deleting_user={deleting_user}
      />
    );
  };
  handleShowEditUserModal = (user) => {
    this.setState({ show_edit_user_modal: true });
    this.setState({ editing_user: user });
  };
  handleCloseEditUserModal = () => {
    this.setState({ show_edit_user_modal: false });
  };
  renderEditUserModal = (editing_user) => {
    return (
      <EditUser handleCloseEditUserModal={this.handleCloseEditUserModal} updateUserList={this.updateUserList} editing_user={editing_user} propertyName={this.props.propertyName}/>
    );
  };
  render() {
    let userlistitem =
      this.state.userlist.length &&
      this.state.userlist.map((user, index) => (
        <div key={user.id}>
          <div className="user-list-table__user-info">
            <div className="user-list-table__user-info__serial-number">{user.serial_number}</div>
            <div className="user-list-table__user-info__first-name">{user.first_name}</div>
            <div className="user-list-table__user-info__last-name">{user.last_name}</div>
            <div className="user-list-table__user-info__user-name">{user.user_name}</div>
            <div className="user-list-table__user-info__email">{user.user_email}</div>
            <div className="selection-buttons">
              <button className="selection-button" onClick={() => this.handleShowEditUserModal(user)}>
                <img src={EditIcon} className="selection-icon" alt="edit-icon" />
                <span>Edit</span>
              </button>
             
              <button className="selection-button" onClick={() => this.handleShowDeleteUserModal(user)} disabled={this.props.user_name === user.user_name}>
                <img src={DeleteIcon} className="selection-icon" alt="delete-icon" />
                <span>Delete</span>
              </button>
                
            </div>
          </div>
          {/* {list to be displayed for mobile and tablet view} */}
          <div className=" user-list-table__user-info--mobile">
            <div className="user-list-table__user-info__first-name">
              <p className="text-label">First Name</p>
              <p className="list-data"> {user.first_name}</p>
            </div>
            <div className="user-list-table__user-info__last-name">
              <p className="text-label">Second Name</p>
              <p className="list-data"> {user.last_name}</p>
            </div>
            <div className="user-list-table__user-info__user-name">
              <p className="text-label">User Name</p>
              <p className="list-data"> {user.user_name}</p>
            </div>
            <div className="user-list-table__user-info__email">
              <p className="text-label">Email</p>
              <p className="list-data"> {user.user_email}</p>
            </div>
            <div className="selection-buttons">
              <button className="selection-button" onClick={() => this.handleShowEditUserModal(user)}>
                <img src={EditIcon} className="selection-icon" alt="edit-icon" />
                <span>Edit</span>
              </button>
             
          <button className="selection-button" onClick={() => this.handleShowDeleteUserModal(user)}>
            <img src={DeleteIcon} className="selection-icon" alt="delete-icon" />
            <span>Delete</span>
          </button>
  
            </div>
          </div>
        </div>
      ));
    return (
      <>
        {this.state.show_add_user_modal ? this.renderAddUserModal() : null}
        {this.state.show_delete_user_modal ? this.renderDeleteUserModal(this.state.deleting_user) : null}
        {this.state.show_edit_user_modal ? this.renderEditUserModal(this.state.editing_user) : null}
        <div className="user-list-page">
          <div className="alignitems">
            <p className="userlist-text">Users List</p>
            <button className="add-user-button" onClick={this.handleShowAddUserModal}>
              <img src={addIcon} alt="" className="add-icon" />
              Add User
            </button>
          </div>
          <div className="user-list-table">
            <div className="user-list-table__category-title category-title">
              <div className="user-list-table__user-info__serial-number">Sr.no</div>
              <div className="user-list-table__user-info__first-name">First Name</div>
              <div className="user-list-table__user-info__last-name">Last Name</div>
              <div className="user-list-table__user-info__user-name">User Name</div>
              <div className="user-list-table__user-info__email">Email</div>
            </div>
            <div className="list-table">{this.state.is_loading ? <Loader /> : this.state.userlist.length ? userlistitem : null}</div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user_name: state.authentication.user_name,
  };
};

export default connect(mapStateToProps, null)(UserListPage);

