import React, { Component } from "react";
import UserListPage from "./userlist_page/UserListPage";
import "./homepageinfocontainer.css";
import PropertyListPage from "./propertylist_page/PropertyListPage";

export class HomePageInfoContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: "user_list_page",
       propertyName:[]
    };
  }
  componentDidMount(){
    // console.log(this.state.propertyName)
  }
  updatePropertyList = (newPropertyValue) => {
    this.setState({ propertyName: newPropertyValue }, () => {
// This will log the updated state
    });
  
   
  };
  render() {
    return (
      <div className="info-container">{this.props.selected_info_page === "user_list_page" ? <UserListPage propertyName={this.state.propertyName} /> : <PropertyListPage updatePropertyList={this.updatePropertyList} />}</div>
    );
  }
}

export default HomePageInfoContainer;
