import React, { Component } from "react";
import Logout from "../logout/Logout";
import LogoutIcon from "../../../../../assets/images/components/logout-icon.svg";
import "./logoutbutton.css";
import { connect } from "react-redux";

class LogoutButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_logout_modal: false,
    };
  }
  handleLogoutModal = () => {
    this.setState((prevstate) => ({ show_logout_modal: !prevstate.show_logout_modal }));
  };

  render() {
    return (
      <>
        {this.state.show_logout_modal ? (
          <Logout handleLogoutModal={this.handleLogoutModal} handleUserLogout={this.props.handleUserLogout} />
        ) : null}
        <button onClick={this.handleLogoutModal} className="settings-button">
          <img src={LogoutIcon} alt="" className="settings-icon" />
          Logout
        </button>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_name: state.authentication.user_name,
  };
};

export default connect(mapStateToProps, null)(LogoutButton);
