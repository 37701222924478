import React, { Component } from "react";
import NiurixLogo from "./assets/images/components/NiurixLogo.png";
import { url } from "./configurations/serverlinks";
import { axiosRequest } from "./helpers/axiosRequest";
import FormLoader from "./shared/loaders/FormLoader";

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      is_loading: false

    };
  }
  handleEmail = (event) => {
    this.setState({ email: event.target.value });
  };
  submitEmailDetails = (event) => {
    let email_credentials = this.state.email ;
    this.setState({ is_loading: true })
    event.preventDefault();
    axiosRequest(`${url}/PasswordResetRequest?email=${email_credentials}`, "POST", null,{
      "Content-Type": "application/JSON",
    })
      .then((response) => {
        if (response.data === "token sent to your email") {
          alert("Please check your email");
        } else {
          alert("enter valid email");
        }
        this.setState({ is_loading: false })
      })
      .catch((err) => {
        console.log(err);
        this.setState({ is_loading: false })
      });
  };

  render() {
    return (
      <>
        <div className="login-page">
          <img src={NiurixLogo} className="login-page__niurix-logo" alt="niurix-logo" />
          <p className="login-page__welcome-title">Confirm your Email</p>
          <p className="login-info">Please enter your email address to get a link for resetting password</p>
          <form action="" className="login-form" onSubmit={this.submitEmailDetails}>
            <div>
              <label htmlFor="user-email" className="login-form__label">
                Enter Email Address
              </label>
              <input
                type="email"
                className="login-form__user-input"
                value={this.state.email}
                onChange={this.handleEmail}
                spellCheck={false}
                autoFocus
              />
            </div>

            <div className="login-form__login-section">
              <img src="" alt="" />
              <button type="submit" className="login-form__login-button">
                {this.state.is_loading ? <FormLoader /> : "Send Email"}
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default ForgotPassword;
