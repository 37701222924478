import React, { Component } from "react";
import "./homepage.css";
import HomePageInfoContainer from "./homepage_info_container/HomePageInfoContainer";
import HomePageHeader from "./home_page_header/HomePageHeader";
import HomePageSidebar from "./home_page_sidebar/HomePageSidebar";

export class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_info_page: "property_list_page",
    };
  }
  setInfoContainerPage = (selected_page) => {
    this.setState({ selected_info_page: selected_page });
  };

  render() {
    return (
      <div className="home-page">
        <div className="home-page__header">
          <HomePageHeader />
        </div>
        <div className="home-page__sidebar">
          <HomePageSidebar setInfoContainerPage={this.setInfoContainerPage} />
        </div>
        <div className="home-page__info-container">
          <HomePageInfoContainer selected_info_page={this.state.selected_info_page} />
        </div>
      </div>
    );
  }
}
