import React from "react";
import "./loader.css";

function Loader(props) {
  return (
    <div className="preloader"></div>
  );
}

export default Loader;
