import React, { Component } from "react";
import Modal from "../../../../../portals/modal/Modal";
import { ReactComponent as CloseModalIcon } from "../../../../../../assets/images/components/close-modal-icon.svg";
import { axiosRequest } from "../../../../../../helpers/axiosRequest";
import { url } from "../../../../../../configurations/serverlinks";
import FormLoader from "../../../../../../shared/loaders/FormLoader";
import MultiDropdown from "../MultiDropdown";
import { connect } from "react-redux";



export class EditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: "",
      last_name: "",
      user_email: "",
      user_name: "",
      user_id: "",
      is_admin: null,
      isloading: false,
      properties:[],
      editproperties:[],
      selectedOptions:[],
    };
  }

   handleSelectedValues(selectedValues) {
    // Do something with the selected values, e.g., update state in the parent component
    console.log('Selected Values:', selectedValues);
  }
  
  updateEditProperties = (newPropertyValue) => {
    // Assuming newPropertyValue is an array of objects with "id" and "property_name" properties
    const formattedEditProperties = newPropertyValue.map((property) => ({
      value: property.id,
      label: property.property_name,
    }));
  
    this.setState({ editproperties: formattedEditProperties }, () => {
      console.log(this.state.editproperties);
    });
    // this.updateEditProperties(this.state.editproperties)
  }
  updateSelectedOptions2 = (newPropertyValue) => {
    // Assuming newPropertyValue is an array of objects with "id" and "property_name" properties
    const formattedEditProperties = newPropertyValue.map((property) => ({
      value: property.id,
      label: property.property_name,
    }));
  
    this.setState({ selectedOptions: formattedEditProperties }, () => {
      // console.log(this.state.editproperties);
    });
    // this.updateEditProperties(this.state.editproperties)
  }
  updateSelectedOptions = (newPropertyValue) => {
    this.setState({ selectedOptions: newPropertyValue }, () => {
      // console.log(this.state.selectedOptions); // This will log the updated state
    });
    // console.log(newPropertyValue);
  }
  
  updateProperties = (newPropertyValue) => {
    this.setState({ properties: newPropertyValue }, () => {
      // console.log(this.state.properties); // This will log the updated state
    });
    // console.log(newPropertyValue);
  }
  componentDidMount() {
    console.log(this.props.user_name);
    this.setState({
      first_name: this.props.editing_user.first_name,
      last_name: this.props.editing_user.last_name,
      user_name: this.props.editing_user.user_name,
      user_email: this.props.editing_user.user_email,
      is_admin: this.props.editing_user.is_admin,
      properties: this.state.properties
    });
    let user_edit_credential =  this.props.editing_user.id;
    let access_token = sessionStorage.getItem("access_token");
    axiosRequest(`${url}/getPropertiesByUserId?userId=${user_edit_credential}`, "GET", null, { Authorization: `Bearer ${access_token} ` })
      .then((response) => {
        if(response.data !== ""){
      this.updateEditProperties(response.data)
      this.updateSelectedOptions2(response.data)
      }
      })
      .catch((error) => {
        alert(error);
      });
  }
  submitEditedUserDetails = (event) => {
    event.preventDefault();
    this.setState({ isloading: true });
    let user_id = this.props.editing_user.id;
    let new_user_credentials = {
      firstName: this.state.first_name,
      lastName: this.state.last_name,
      userName: this.state.user_name,
      email: this.state.user_email,
      userId: user_id,
      isAdmin: this.state.is_admin,
    
    };
    let  edit_user_property ={
      userId: this.props.editing_user.id,
      propertyId: this.state.properties
    }
    let access_token = sessionStorage.getItem("access_token");
    axiosRequest(`${url}/editUser`, "PUT", new_user_credentials, {
      "Content-Type": "application/JSON",
      Authorization: `Bearer ${access_token}`,
    })
    .then(
      ()=> axiosRequest(`${url}/editAssignProperty`, "PUT", edit_user_property,{
        "Content-Type": "application/JSON",
        Authorization: `Bearer ${access_token}`,
      })
    )
      .then(() => axiosRequest(`${url}/getUsers`, "GET", null, { Authorization: `Bearer ${access_token} ` }))
      .then((resp) => {
        if (resp.status === false) {
          this.setState({ isloading: false });
          return alert(resp.message);
        }
        this.props.updateUserList(resp.data)
        this.props.handleCloseEditUserModal()
      })
      .catch((err) => {
        this.setState({ isloading: false });
        alert(err);
      });
  };
  handleUserName = (event) => {
    this.setState({ user_name: event.target.value });
  };
  handleLastName = (event) => {
    this.setState({ last_name: event.target.value });
  };
  handleFirstName = (event) => {
    this.setState({ first_name: event.target.value });
  };
  handleEmail = (event) => {
    this.setState({ user_email: event.target.value });
  };
  toggleAdminState = () => {
    this.setState((prevstate) => ({ is_admin: !prevstate.is_admin }));
  };

  render() {
    return (
      <Modal modal_name="main-modal">
        <div className="modal-container">
          <div className="modal-content">
            <p className="modal-title"> Edit User</p>
            <CloseModalIcon className="modal-close-icon" onClick={this.props.handleCloseEditUserModal} />
            <form action="" className="modal-form" onSubmit={this.submitEditedUserDetails}>
              <div className="modal-form__input-section">
                <label htmlFor="username" className="modal-form__label-text">
                  First Name
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.first_name}
                  onChange={this.handleFirstName}
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="Email" className="modal-form__label-text">
                  Last Name
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.last_name}
                  onChange={this.handleLastName}
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="password" className="modal-form__label-text">
                  User Name
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.user_name}
                  onChange={this.handleUserName}
                  required
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="confirm-password" className="modal-form__label-text">
                  Email
                </label>
                <input
                  type="email"
                  className="modal-form__input"
                  value={this.state.user_email}
                  onChange={this.handleEmail}
                  required
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="confirm-password" className="modal-form__label-text">
                  Properties
                </label>
                <MultiDropdown propertyName={this.props.propertyName} properties={this.updateProperties} editProperties={this.state.editproperties} selectedOptions={this.state.selectedOptions} updateSelectedOptions={this.updateSelectedOptions} />
              </div>
              {this.props.user_name !== this.state.user_name && (
              <div className="modal-form__input-section modal-form__input-section-checkbox">
                <input
                  type="checkbox"
                  id="user-type"
                  name="user-type"
                  value={this.state.is_admin}
                  onChange={this.toggleAdminState}
                  checked={this.state.is_admin}
                />
                <label htmlFor="user-type" className="modal-form__label-text">
                  Set User as Admin
                </label>
              </div>
            )}
              <div className="modal-form__footer">
                <button type="submit" value="Save" className="modal-form__sumbit-button">
                  {this.state.isloading ? <FormLoader /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_name: state.authentication.user_name,
  };
};

export default connect(mapStateToProps, null)(EditUser);
