import * as LoginActionTypes from "../actions/AuthActions";
const initState = {
  user_type: null,
  user_name: null,
  is_user_logged_in: false,
 
};

const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case LoginActionTypes.SET_USER_TYPE:
      return {
        ...state,
        user_type: action.payload,
      };
    case LoginActionTypes.SET_USER_NAME:
      return {
        ...state,
        user_name: action.payload,
      };

    case LoginActionTypes.LOGIN_USER:
      return {
        ...state,
        is_user_logged_in: true,
      };
    case LoginActionTypes.LOGOUT_USER:
      return {
        ...state,
        is_user_logged_in: false,
        user_type: null,
        user_name: null,
      };
    default:
      return state;
  }
};

export default AuthReducer;
