import Modal from "../../../../portals/modal/Modal";
import React, { Component } from "react";
import { ReactComponent as CloseModalIcon } from "../../../../../assets/images/components/close-modal-icon.svg";
import { axiosRequest } from "../../../../../helpers/axiosRequest";
import { url } from "../../../../../configurations/serverlinks";
import OpenEyeIcon from "../../../../../assets/images/components/Icon feather-eye.svg";
import CloseEyeIcon from "../../../../../assets/images/components/Icon feather-eye-off.svg";
import { connect } from "react-redux";
import * as LoginActionTypes from "../../../../../store/actions/AuthActions";
import FormLoader from "../../../../../shared/loaders/FormLoader";

export class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      old_password: null,
      new_password: null,
      confirm_password: null,
      show_old_password: false,
      show_new_password: false,
      show_confirm_password: false,
      isloading: false,

    };
  }
  handleOldPassword = (event) => {
    this.setState({ old_password: event.target.value });
  };
  handleNewPassword = (event) => {
    this.setState({ new_password: event.target.value });
  };
  handleConfirmPassword = (event) => {
    this.setState({ confirm_password: event.target.value });
  };
  toggleOldPasswordVisibility = () => {
    this.setState((prevstate) => ({ show_old_password: !prevstate.show_old_password }));
  };
  toggleNewPasswordVisibility = () => {
    this.setState((prevstate) => ({ show_new_password: !prevstate.show_new_password }));
  };
  toggleConfirmPasswordVisibility = () => {
    this.setState((prevstate) => ({ show_confirm_password: !prevstate.show_confirm_password }));
  };
  submitNewUserDetails = (event) => {
    event.preventDefault();
    this.setState({ isloading: true });
    if (this.state.new_password !== this.state.confirm_password) {
      this.setState({ isloading: false })
      return alert("Please check your new password and confirm password");

    }
    let new_password_credentials = {
      oldPassword: this.state.old_password,
      newPassword: this.state.confirm_password,
    };
    let access_token = sessionStorage.getItem("access_token");
    axiosRequest(`${url}/changePassword`, "PUT", new_password_credentials, {
      "Content-Type": "application/JSON",
      Authorization: `Bearer ${access_token}`,
    })
      .then((response) => {
        if (response.data.status === false) {
          this.setState({ isloading: false })
          alert("Old password is not correct. Please try again");
        }
        else {
          alert("Password has been changed. Please login again.");
          this.setState({ isloading: false })
          this.props.closeChangePasswordModal();
          this.props.logoutUser();
        }
      })

      .catch((error) => {
        this.setState({ isloading: false });
        alert(error);
      });
  };
  render() {
    return (
      <Modal modal_name="change-password-modal">
        <div className="modal-container">
          <div className="modal-content">
            <p className="modal-title"> Change Password</p>
            <CloseModalIcon className="modal-close-icon" onClick={this.props.closeChangePasswordModal} />
            <form action="" className="modal-form" onSubmit={this.submitNewUserDetails}>
              <div className="modal-form__input-section">
                <label htmlFor="username" className="modal-form__label-text">
                  Old Password
                </label>
                <div className="password-container">
                  <input
                    type={this.state.show_old_password ? "text" : "password"}
                    id="user_password"
                    className="modal-form__input"
                    value={this.state.old_password}
                    onChange={this.handleOldPassword}
                    required
                    autoComplete="off"
                  />
                  <img
                    src={this.state.show_old_password ? OpenEyeIcon : CloseEyeIcon}
                    alt=""
                    className="login-form__password-icon"
                    onClick={this.toggleOldPasswordVisibility}
                  />
                </div>
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="username" className="modal-form__label-text">
                  New Password
                </label>
                <div className="password-container">
                  <input
                    type={this.state.show_new_password ? "text" : "password"}
                    id="user_password"
                    className="modal-form__input"
                    value={this.state.new_password}
                    onChange={this.handleNewPassword}
                    required
                    autoComplete="off"
                  />
                  <img
                    src={this.state.show_new_password ? OpenEyeIcon : CloseEyeIcon}
                    alt=""
                    className="login-form__password-icon"
                    onClick={this.toggleNewPasswordVisibility}
                  />
                </div>
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="username" className="modal-form__label-text">
                  Confirm New Password
                </label>
                <div className="password-container">
                  <input
                    type={this.state.show_confirm_password ? "text" : "password"}
                    id="user_password"
                    className="modal-form__input"
                    value={this.state.confirm_password}
                    onChange={this.handleConfirmPassword}
                    required
                    autoComplete="off"
                  />
                  <img
                    src={this.state.show_confirm_password ? OpenEyeIcon : CloseEyeIcon}
                    alt=""
                    className="login-form__password-icon"
                    onClick={this.toggleConfirmPasswordVisibility}
                  />
                </div>
              </div>
              <div className="modal-form__footer">
                <button type="submit" value="Save" className="modal-form__sumbit-button">
                  {this.state.isloading ? <FormLoader /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: LoginActionTypes.LOGIN_USER,
        // payload: option,
      });
    },
    logoutUser: () => {
      dispatch({
        type: LoginActionTypes.LOGOUT_USER,
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(ChangePassword);
