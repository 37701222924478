import React, { Component } from "react";
import Modal from "../../../../../portals/modal/Modal";
import { ReactComponent as CloseModalIcon } from "../../../../../../assets/images/components/close-modal-icon.svg";
import { axiosRequest } from "../../../../../../helpers/axiosRequest";
import { url } from "../../../../../../configurations/serverlinks";
import FormLoader from "../../../../../../shared/loaders/FormLoader";
import MultiDropdown from "../MultiDropdown";


export class AddUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: null,
      last_name: null,
      user_name: null,
      user_email: null,
      is_admin: false,
      isloading: false,
      properties:[],
      selectedOptions:[],
    };
  }

  updateSelectedOptions = (newPropertyValue) => {
    this.setState({ selectedOptions: newPropertyValue }, () => {
      console.log(this.state.selectedOptions); // This will log the updated state
    });
    // console.log(newPropertyValue);
  }
  

  updateProperties = (newPropertyValue) => {
    this.setState({ properties: newPropertyValue }, () => {
      console.log(this.state.properties); // This will log the updated state
    });
    console.log(newPropertyValue);
   
  };

  handleUserName = (event) => {
    this.setState({ user_name: event.target.value });
  };
  handleLastName = (event) => {
    this.setState({ last_name: event.target.value });
  };
  handleFirstName = (event) => {
    this.setState({ first_name: event.target.value });
  };
  handleEmail = (event) => {
    this.setState({ user_email: event.target.value });
  };
  toggleAdminState = () => {
    this.setState((prevstate) => ({ is_admin: !prevstate.is_admin }));
  };
  submitNewUserDetails = (event) => {
    event.preventDefault();
    this.setState({ isloading: true });
    let new_user_credentials = {
      firstName: this.state.first_name,
      lastName: this.state.last_name,
      userName: this.state.user_name,
      email: this.state.user_email,
      isAdmin: this.state.is_admin,
      // properties: this.state.properties
    };
    let access_token = sessionStorage.getItem("access_token");
    axiosRequest(`${url}/addUser`, "POST", new_user_credentials, {
      "Content-Type": "application/JSON",
      Authorization: `Bearer ${access_token}`,
    })
    .then((response)=>{
      console.log(response.data);
      let new_user_property ={
        userId: response.data.id,
        propertyId:this.state.properties
      }
      if(response.data.id){
        return axiosRequest(`${url}/assignPropertyToUser`, "POST", new_user_property,{
          "Content-Type": "application/JSON",
          Authorization: `Bearer ${access_token}`,
        });
      }
    })
      .then((response) => {
        // console.log(response)
        if (response) {
          return axiosRequest(`${url}/getUsers`, "GET", null, { Authorization: `Bearer ${access_token} ` });
        }
        else {
          this.setState({ isloading: false })
          // alert("Username already exists, please try with a new username.");
          alert("Error while adding user(User already exists or there is some issue while sending email)");
        }

      })
      .catch((err)=>{
        this.setState({ isloading: false });
        alert(err);
        console.log(err)
      })
      .then((response) => {
        if (response) {
          this.props.updateUserList(response.data);
          this.props.handleCloseAddUserModal();
        }

      })
      .catch((err) => {
        this.setState({ isloading: false });
        alert(err);
        console.log(err)
      });
  };

  render() {
    return (
      <Modal modal_name="main-modal">
        <div className="modal-container">
          <div className="modal-content">
            <p className="modal-title"> Add User</p>
            <CloseModalIcon className="modal-close-icon" onClick={this.props.handleCloseAddUserModal} />
            <form action="" className="modal-form" onSubmit={this.submitNewUserDetails}>
              <div className="modal-form__input-section">
                <label htmlFor="username" className="modal-form__label-text">
                  First Name
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.first_name}
                  onChange={this.handleFirstName}
                  spellCheck={false}
                  required={true}
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="Email" className="modal-form__label-text">
                  Last Name
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.last_name}
                  onChange={this.handleLastName}
                  spellCheck={false}
                  required={true}
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="password" className="modal-form__label-text">
                  User Name
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.user_name}
                  onChange={this.handleUserName}
                  spellCheck={false}
                  required={true}
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="confirm-password" className="modal-form__label-text">
                  Email
                </label>
                <input
                  type="email"
                  className="modal-form__input"
                  value={this.state.user_email}
                  onChange={this.handleEmail}
                  spellCheck={false}
                  required={true}
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="confirm-password" className="modal-form__label-text">
                  Properties
                </label>
                <MultiDropdown propertyName={this.props.propertyName} properties={this.updateProperties} updateSelectedOptions={this.updateSelectedOptions}/>
              </div>
              <br/>
              <div className="modal-form__input-section modal-form__input-section-checkbox">
                <input
                  type="checkbox"
                  id="user-type"
                  name="user-type"
                  value={this.state.is_admin}
                  onChange={this.toggleAdminState}
                  checked={this.state.is_admin}
                  spellCheck={false}
                />

                <label for="user-type" className="modal-form__label-text">
                  {" "}
                  Set User as Admin
                </label>
              </div>
              <div className="modal-form__footer">
                <button type="submit" value="Add" className="modal-form__sumbit-button">
                  {this.state.isloading ? <FormLoader /> : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AddUser;
