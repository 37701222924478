import React, { Component } from 'react';
import Select from 'react-select';

class MultiDropdown extends Component {
  // constructor(props) {
  //   super(props);
    
  // }

  componentDidMount() {
    this.initializeSelectedOptions();
  }

  initializeSelectedOptions = () => {
    const { editProperties } = this.props;
    if (editProperties && editProperties.length > 0) {
      const selectedOptions = editProperties.map((property) => ({
        value: property.value,
        label: property.label,
      }));
      this.handleChange(selectedOptions);
    }
  };

  handleChange = (selected) => {
    const selectedValues = selected.map((option) => option.value);
    this.props.updateSelectedOptions(selected);
    this.setState({ selectedOptions: selected });
    this.props.properties(selectedValues);
  };

  render() {
    const { propertyName } = this.props;
    const propertyNameOptions = propertyName.map((property) => ({
      value: property.id,
      label: property.property_name,
    }));

    // console.log(this.state.selectedOptions)  
    return (
      <div>
        <br />
        <Select
          className='modal-form__input_properties'
          isMulti
          value={this.props.selectedOptions}
          onChange={this.handleChange}
          options={propertyNameOptions}
        />
      </div>
    );
  }
}

export default MultiDropdown;
