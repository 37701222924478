import React, { Component } from "react";
import PropTypes from "prop-types";

export class OutsideAlerter extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.resetState();
    }
  }

  render() {
    return (
      <div className={this.props.className} ref={this.setWrapperRef}>
        {this.props.children}
      </div>
    );
  }
}

export default OutsideAlerter;
OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired,
};
