import React, { Component } from "react";
import { submitLoginFormData } from "./api";
import { Link } from "react-router-dom";
import "./loginform.css";
import { connect } from "react-redux";
import * as LoginActionTypes from "../../../../store/actions/AuthActions";
import OpenEyeIcon from "../../../../assets/images/components/Icon feather-eye.svg";
import CloseEyeIcon from "../../../../assets/images/components/Icon feather-eye-off.svg";
import FormLoader from "../../../../shared/loaders/FormLoader";
const SESSION_EXPIRY_TIME = 20 * 60 * 1000; // time in milli-seconds
export class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_name: "",
      user_password: "",
      show_password: false,
      is_loading: false,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let data = { username: this.state.user_name, password: this.state.user_password };
    this.setState({ is_loading: true });
    submitLoginFormData(data)
      .then((response) => {
        if (response.data.admin === true) {
          this.props.setUserType("admin");
        } else {
          this.props.setUserType("user");
        }
        if (response.data.username) {
          this.props.setUserName(response.data.username);
        }
     
        // console.log(response.data)
        sessionStorage.removeItem("access_token");
        if (typeof response.data.accessToken === "undefined") {
          this.setState({ is_loading: false });
          alert("Incorrect Username or password, please try again");
        } else {
          this.setState({ is_loading: false })
          sessionStorage.setItem("access_token", `${response.data.accessToken}`);
          this.runlogoutTimer();
          this.props.loginUser();
          this.props.history.push("./")
        }
      })

      .catch((error) => {
        this.setState({ is_loading: false });
        alert(error);
      });
  };
  runlogoutTimer = () => {
    setTimeout(() => {
      let access_token = sessionStorage.getItem("access_token");
      if (access_token) {
        alert("session expired, please login again");
        sessionStorage.removeItem("access_token");
        this.props.logoutUser();
      }
    }, SESSION_EXPIRY_TIME);
  };
  handleUserName = (event) => {
    this.setState({ user_name: event.target.value });
  };
  handleUserPassword = (event) => {
    this.setState({ user_password: event.target.value });
  };
  togglePasswordVisibility = () => {
    this.setState((prevstate) => ({ show_password: !prevstate.show_password }));
  };
  render() {
    return (
      <form action="" className="login-form" onSubmit={this.handleSubmit}>
        <div>
          <label htmlFor="user_name" className="login-form__label">
            Username
          </label>
          <input
            type="text"
            id="user_name"
            className="login-form__user-input"
            value={this.state.user_name}
            onChange={this.handleUserName}
            autoComplete="on"
            required
            spellCheck={false}
            autoFocus
          />
        </div>
        <div>
          <label htmlFor="user_password" className="login-form__label">
            Password
          </label>
          <div className="password-container">
            <input
              type={this.state.show_password ? "text" : "password"}
              id="user_password"
              className="login-form__user-input"
              value={this.state.user_password}
              onChange={this.handleUserPassword}
              required
              autoComplete="off"
              spellCheck={false}
            />
            <img
              src={this.state.show_password ? OpenEyeIcon : CloseEyeIcon}
              alt=""
              className="login-form__password-icon"
              onClick={this.togglePasswordVisibility}
            />
          </div>
        </div>
        <div className="login-form__login-section">
          <Link to="/forgot-password" className="login-form__reset-password-link">
            Forgot Password?
          </Link>

          <button type="submit" className="login-form__login-button">
            {this.state.is_loading ? <FormLoader /> : "Login"}
          </button>
        </div>
      </form>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setUserType: (option) =>
      dispatch({
        type: LoginActionTypes.SET_USER_TYPE,
        payload: option,
      }),
    setUserName: (option) => {
      dispatch({
        type: LoginActionTypes.SET_USER_NAME,
        payload: option,
      });
    },
    loginUser: () => {
      dispatch({
        type: LoginActionTypes.LOGIN_USER,
        // payload: option,
      });
    },
    logoutUser: () => {
      dispatch({
        type: LoginActionTypes.LOGOUT_USER,
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(LoginForm);
