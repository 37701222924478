import axios from "axios";

import { url } from "../configurations/serverlinks";

export const axiosRequest = (link, method, data, headers) => {
  if (link.length === 0) link = url;
  if (method.length === 0) method = "GET";
  return axios({
    method: method,
    url: link,
    data: data,
    headers: headers,
    timeout: 150000000,
  });
};
