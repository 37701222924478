import React, { Component } from "react";
import Modal from "../../../../portals/modal/Modal";
import { ReactComponent as CloseModalIcon } from "../../../../../assets/images/components/close-modal-icon.svg";
import "./logout.css";
import * as LoginActionTypes from "../../../../../store/actions/AuthActions";
import { connect } from "react-redux";

export class Logout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
    };
  }
  handleUserlogout = () => {
    sessionStorage.removeItem("access_token");
    this.props.logoutUser();
  };
  render() {
    return (
      <Modal modal_name="logout-modal">
        <div className="modal-container">
          <div className="modal-content">
            <CloseModalIcon className="modal-close-icon" onClick={this.props.handleLogoutModal} />
            <p className="modal-alert-info">Are you sure you want to Log out?</p>
            <form action="">
              <div className="logout-selection-button">
                <button onClick={this.props.handleLogoutModal} className="modal-form__cancel-button">
                  Cancel
                </button>
                <button onClick={this.handleUserlogout} className="modal-form__sumbit-button">
                  Log out
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: LoginActionTypes.LOGIN_USER,
        // payload: option,
      });
    },
    logoutUser: () => {
      dispatch({
        type: LoginActionTypes.LOGOUT_USER,
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(Logout);
