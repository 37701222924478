import React, { Component } from "react";
import Modal from "../../../../../portals/modal/Modal";
import { ReactComponent as CloseModalIcon } from "../../../../../../assets/images/components/close-modal-icon.svg";
import { axiosRequest } from "../../../../../../helpers/axiosRequest";
import { url } from "../../../../../../configurations/serverlinks";
import FormLoader from "../../../../../../shared/loaders/FormLoader";

export class AddProperty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      property_name: "",
      property_address: "",
      olt_mac_address: "",
      olt_serial_number: "",
      installation_date: "",
      warranty_expiry_date: "",
      contact_person: "",
      contact_number: "",
      email: "",
      isloading: false,
    };
  }
  handlePropertyName = (event) => {
    this.setState({ property_name: event.target.value });
  };
  handleAddress = (event) => {
    this.setState({ property_address: event.target.value });
  };
  handleOltMacAddress = (event) => {
    this.setState({ olt_mac_address: event.target.value });
  };
  handleOltSerialNumber = (event) => {
    this.setState({ olt_serial_number: event.target.value });
  };
  handleInstallationDate = (event) => {
    this.setState({ installation_date: event.target.value });
  };
  handleWarrantyExpirtyDate = (event) => {
    this.setState({ warranty_expiry_date: event.target.value });
  };
  handleContactPerson = (event) => {
    this.setState({ contact_person: event.target.value });
  };
  handleContactNumber = (event) => {
    this.setState({ contact_number: event.target.value });
  };
  handleEmail = (event) => {
    this.setState({ email: event.target.value });
  };
  submitNewPropertyDetails = (event) => {
    event.preventDefault();
    this.setState({ isloading: true });
    let new_property_credentials = {
      propertyName: this.state.property_name,
      address: this.state.property_address,
      macAddress: this.state.olt_mac_address,
      oltSerialNumber: this.state.olt_serial_number,
      installationDate: this.state.installation_date,
      expiryDate: this.state.warranty_expiry_date,
      contactPerson: this.state.contact_person,
      contact_Number: this.state.contact_number,
      email: this.state.email,
    };

    let properties;
    if(this.props.user_type === 'admin'){
      properties = "/getProperties"
    }else if(this.props.user_type === 'user'){
      properties = "/getPropertiesOfUser";
    }
    console.log(properties)
    let access_token = sessionStorage.getItem("access_token");

    axiosRequest(`${url}/addProperty`, "POST", new_property_credentials, {
      "Content-Type": "application/JSON",
      Authorization: `Bearer ${access_token}`,
    })
      .then(() =>  axiosRequest(`${url}${properties}`, "GET", null, { Authorization: `Bearer ${access_token} ` })  
      )
      .then((response) => {
        if (response.status === true) {
          this.setState({ isloading: false });
          return alert(response.message);
        }
        this.props.updatePropertyList(response.data);
        this.props.handleCloseAddPropertyModal();
      })
      .catch((err) => {
        this.setState({ isloading: false });
        alert(err);
      });
  };

  render() {
    return (
      <Modal modal_name="main-modal">
        <div className="modal-container">
          <div className="modal-content">
            <div className="modal-header">
              <p className="modal-title">Add Property</p>
              <CloseModalIcon className="modal-close-icon" onClick={this.props.handleCloseAddPropertyModal} />
            </div>
            <form action="" className="modal-form" onSubmit={this.submitNewPropertyDetails}>
              <div className="modal-form__input-section">
                <label htmlFor="username" className="modal-form__label-text">
                  Property Name
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.property_name}
                  onChange={this.handlePropertyName}
                  required={true}
                  spellCheck={false}
                  autoFocus
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="confirm-password" className="modal-form__label-text">
                  Property Owner
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.contact_person}
                  onChange={this.handleContactPerson}
                  required={true}
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section modal-form__input-section--address">
                <label htmlFor="Email" className="modal-form__label-text">
                  Address
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.property_address}
                  onChange={this.handleAddress}
                  required={true}
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="confirm-password" className="modal-form__label-text">
                  Contact Number
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.contact_number}
                  onChange={this.handleContactNumber}
                  required={true}
                  spellCheck={false}
                />
              </div>

              <div className="modal-form__input-section">
                <label htmlFor="confirm-password" className="modal-form__label-text">
                  Email
                </label>
                <input type="email" className="modal-form__input" value={this.state.email} onChange={this.handleEmail} required />
              </div>
              <div className="modal-form__input-section modal-form__input-section--date">
                <label htmlFor="confirm-password" className="modal-form__label-text">
                  Install Date
                </label>
                <input
                  type="date"
                  className="modal-form__input"
                  value={this.state.installation_date}
                  onChange={this.handleInstallationDate}
                  required={true}
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section modal-form__input-section--date">
                <label htmlFor="confirm-password" className="modal-form__label-text">
                  Warranty Expiry Date
                </label>
                <input
                  type="date"
                  className="modal-form__input"
                  value={this.state.warranty_expiry_date}
                  onChange={this.handleWarrantyExpirtyDate}
                  required={true}
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section  ">
                <label htmlFor="Email" className="modal-form__label-text">
                  OLT MAC Address
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.olt_mac_address}
                  onChange={this.handleOltMacAddress}
                  required={true}
                  spellCheck={false}
                />
              </div>
              <div className="modal-form__input-section">
                <label htmlFor="password" className="modal-form__label-text">
                  OLT Sr.No
                </label>
                <input
                  type="text"
                  className="modal-form__input"
                  value={this.state.olt_serial_number}
                  onChange={this.handleOltSerialNumber}
                  required={true}
                  spellCheck={false}
                />
              </div>

              <div className="modal-form__footer">
                <button type="submit" className="modal-form__sumbit-button">
                  {this.state.isloading ? <FormLoader /> : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AddProperty;
