import React, { Component } from "react";
import DeleteIcon from "../../../../../assets/images/components/delete-icon.svg";
import EditIcon from "../../../../../assets/images/components/edit-icon.svg";
import { axiosRequest } from "../../../../../helpers/axiosRequest";
import { url } from "../../../../../configurations/serverlinks";
import AddProperty from "./add_property/AddProperty";
import DeleteProperty from "./delete_property/DeleteProperty";
import EditProperty from "./edit_property/EditProperty";
import "./propertylistpage.css";
import Loader from "../../../../../shared/loaders/Loader";
import addIcon from "../../../../../../src/assets/images/components/add-icon.svg";
import { connect } from "react-redux";

export class PropertyListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      property_list: [],
      show_add_property_modal: false,
      show_delete_property_modal: false,
      show_edit_property_modal: false,
      show_accordion_content: false,
      deleting_property: {},
      editing_property: {},
      selected_property_serial_number: null,
      is_loading: true,
    };
  }

  componentDidMount() {
    let properties;
    if (this.props.user_type === "admin") {
      properties = "/getProperties";
    } else if (this.props.user_type === "user") {
      properties = "/getPropertiesOfUser";
    }

    let access_token = sessionStorage.getItem("access_token");
    axiosRequest(
      `${url}${properties}`,
      "GET",
      null,
      { Authorization: `Bearer ${access_token} ` }
    )
      .then((response) => {
        if (response.data !== 'no any property is assigned to User!!') {
          this.setState({ is_loading: false, property_list: response.data }, () => {
            this.props.updatePropertyList(this.state.property_list);
          });
        } else{
          this.setState({is_loading: false})
        }
      })
      .catch((err) => {
        this.setState({ is_loading: false });
        alert(err.response);
      });
  }

  updatePropertyList = (updated_property_list) => {
    this.setState({ property_list: updated_property_list });
  };

  handleShowAddPropertyModal = () => {
    this.setState({ show_add_property_modal: true });
  };
  handleCloseAddPropertyModal = () => {
    this.setState({ show_add_property_modal: false });
  };
  renderAddPropertyModal = () => {
    return (
      <AddProperty
        handleCloseAddPropertyModal={this.handleCloseAddPropertyModal}
        updatePropertyList={this.updatePropertyList}
        user_type={this.props.user_type}
      />
    );
  };
  toggleAccordion = (property) => {
    if (this.state.selected_property_serial_number === property.serial_number) {
      this.setState({
        selected_property_serial_number: null,
      });
    } else {
      this.setState({
        selected_property_serial_number: property.serial_number,
      });
    }
  };

  handleShowDeletePropertyModal = (property) => {
    this.setState({ show_delete_property_modal: true });
    this.setState({ deleting_property: property });
  };

  handleCloseDeletePropertyModal = () => {
    this.setState({ show_delete_property_modal: false });
  };
  renderDeletePropertyModal = (deleting_property) => {
    return (
      <DeleteProperty
        handleCloseDeletePropertyModal={this.handleCloseDeletePropertyModal}
        updatePropertyList={this.updatePropertyList}
        deleting_property={deleting_property}
        user_type={this.props.user_type}
      />
    );
  };
  handleShowEditPropertyModal = (property) => {
    this.setState({ show_edit_property_modal: true });
    this.setState({ editing_property: property });
  };
  handleCloseEditPropertyModal = () => {
    this.setState({ show_edit_property_modal: false });
  };
  renderEditPropertyModal = (editing_property) => {
    return (
      <EditProperty
        handleCloseEditPropertyModal={this.handleCloseEditPropertyModal}
        updatePropertyList={this.updatePropertyList}
        editing_property={editing_property}
        user_type={this.props.user_type}
      />
    );
  };
  render() {
    const { user_type } = this.props;

    let propertylistitem =
      this.state.property_list && this.state.property_list.length > 0 ? (
        this.state.property_list.map((property, index) => (
          <div key={property.id}>
            <div
              key={property.id}
              onClick={() => {
                this.toggleAccordion(property);
              }}
              className={
                this.state.selected_property_serial_number ===
                property.serial_number
                  ? "list-data--highlight-background"
                  : "list-data--desktop"
              }
            >
              <div className="property-list-table__property-info">
                <div className="user-list-table__user-info__serial-number">
                  {property.serial_number}
                </div>
                <div className="user-list-table__user-info__first-name">
                  {property.property_name}
                </div>
                <div className="user-list-table__user-info__last-name">
                  {property.address}
                </div>
                <div className="user-list-table__user-info__email">
                  {property.installation_date}
                </div>
                <div className="user-list-table__user-info__email">
                  {property.expiry_date}
                </div>
                <div className="selection-buttons">
                {user_type === 'admin' && (
                  <button
                    className="selection-button"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.handleShowEditPropertyModal(property);
                    }}
                    disabled={user_type === "user"}
                  >
                    <img
                      src={EditIcon}
                      className="selection-icon"
                      alt="edit-icon"
                    />
                    <span>Edit</span>
                  </button>
                )}
                 {user_type === 'admin' && (
                  <button
                    className="selection-button"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.handleShowDeletePropertyModal(property);
                    }}
                    disabled={user_type === "user"}
                  >
                    <img
                      src={DeleteIcon}
                      className="selection-icon"
                      alt="delete-icon"
                    />
                    <span>Delete</span>
                  </button>
                 )}
                </div>
              </div>
              <div
                className={
                  this.state.selected_property_serial_number ===
                  property.serial_number
                    ? " property-list-table__accordion--display"
                    : "property-list-table__accordion property-list-table__accordion--none"
                }
              >
            <div className="contact-person">
              <label htmlFor="contact-person" className="text-label">
                Contact Person
              </label>
              <div className="category-info">{property.contact_person}</div>
            </div>
            <div className="contact_number">
              <label htmlFor="contact_number" className="text-label">
                Contact Number
              </label>
              <div className="category-info">{property.contact_number}</div>
            </div>
            <div className="contact_email">
              <label htmlFor="contact_email" className="text-label">
                Contact Email
              </label>
              <div className="category-info">{property.email}</div>
            </div>

            <div className="mac_address">
              <label htmlFor="mac_address" className="text-label">
                OLT Mac Address
              </label>
              <div className="category-info">{property.mac_address}</div>
            </div>
            <div className="serial_number">
              <label htmlFor="serial_number" className="text-label">
                OLT Serial Number:
              </label>
              <div className="category-info">{property.olt_serial_number}</div>
            </div>
              </div>
            </div>
          
          </div>
        ))
      ) : (
        <div  style={{color:"white", marginTop:'50px'}}>No properties available. Contact Admin</div>
      );

    return (
      <>
        {this.state.show_add_property_modal
          ? this.renderAddPropertyModal()
          : null}
        {this.state.show_delete_property_modal
          ? this.renderDeletePropertyModal(this.state.deleting_property)
          : null}
        {this.state.show_edit_property_modal
          ? this.renderEditPropertyModal(this.state.editing_property)
          : null}
        {this.state.property_list ? (
          <div className="user-list-page">
            <div className="alignitems">
              <p className="userlist-text">Property List</p>
              {user_type === 'admin' && (
      <button className="add-user-button" onClick={this.handleShowAddPropertyModal}>
        <img src={addIcon} alt="" className="add-icon" />
        Add Property
      </button>
    )}
            </div>
            <div className="user-list-table">
              <div className="property-list-table__category-title category-title">
                <div className="user-list-table__user-info__serial-number">
                  Sr.no
                </div>
                <div className="user-list-table__user-info__first-name">
                  Property Name
                </div>
                <div className="user-list-table__user-info__last-name">
                  Address
                </div>
                <div className="user-list-table__user-info__email">
                  Install date
                </div>
                <div className="user-list-table__user-info__email">
                  Warranty Expiry date
                </div>
              </div>
              <div className="list-table">
                {this.state.is_loading ? <Loader /> : propertylistitem}
              </div>
            </div>
          </div>
        ) : (
          <> <div style={{color:"white", marginTop:'50px'}}> No Property Assigned</div></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_type: state.authentication.user_type,
  };
};

export default connect(mapStateToProps, null)(PropertyListPage);
