import "./App.css";
import CreatePass from "./CreatePass";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import LoginPage from "./components/main_container/login_page/LoginPage";
import { connect } from "react-redux";
import React, { Component } from 'react'
import { HomePage } from "./components/main_container/home_page/HomePage";
import ProtectedRoute from "./ProtectedRoute";

export class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <ProtectedRoute exact path="/" component={HomePage} />
          <Route path="/savePassword" component={CreatePass} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/login" component={LoginPage} />
          {/* <Route path="/property-list-page" component={PropertyListPage} /> */}
        </Switch>
      </Router>

    )
  }
}


const mapStateToProps = (state) => {
  return {
    is_user_logged_in: state.authentication.is_user_logged_in,
  };
};
export default connect(mapStateToProps, null)(App);
